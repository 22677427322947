import ky from "ky";

import { getEnv } from "@solvari/utils";

import type { LocaleMorpheus } from "@/locales.ts";

import { localeToMorpheus } from "@/locales.ts";

import type AddressInputTranslations from "../cdn-reference/address_input.json";
import type AdminTranslations from "../cdn-reference/admin_vue.json";
import type MainSiteTranslations from "../cdn-reference/client.json";
import type CommonFeTranslations from "../cdn-reference/common_fe.json";
import type ClientCompanyCardTranslations from "../cdn-reference/company_card.json";
import type RegistrationTranslations from "../cdn-reference/company_registration_form.json";
import type ContactFormTranslations from "../cdn-reference/contact_form.json";
import type CustomerCrmApiTranslations from "../cdn-reference/customer_crm_api.json";
import type CustomerFinancialDashboard from "../cdn-reference/customer_financial_dashboard.json";
import type CustomerProjectsTranslations from "../cdn-reference/customer_projects.json";
import type FormsTranslations from "../cdn-reference/forms.json";
import type GeneralTranslations from "../cdn-reference/general.json";
import type InboxTranslations from "../cdn-reference/inbox.json";
import type MainSiteAffiliateTranslations from "../cdn-reference/main_site_affiliate.json";
import type MainSiteBlogTranslations from "../cdn-reference/main_site_blog.json";
import type MainSiteBrochureTranslations from "../cdn-reference/main_site_brochure.json";
import type MainSiteCompanyWallTranslations from "../cdn-reference/main_site_company_wall.json";
import type MainSiteHomepageTranslations from "../cdn-reference/main_site_homepage.json";
import type MainSiteSearchTranslations from "../cdn-reference/main_site_search.json";
import type MyTranslations from "../cdn-reference/my_solvari.json";
import type CustomerTranslations from "../cdn-reference/pro_vue.json";
import type ReviewFormTranslations from "../cdn-reference/review_form.json";
import type ReviewsTranslations from "../cdn-reference/reviews.json";

import { defineI18n } from "./useI18n.ts";

function defineI18nMorpheus<
  Translations,
  Locales extends LocaleMorpheus = LocaleMorpheus,
>(path: string) {
  return defineI18n((locale: Locales) => {
    return ky
      .get(
        `${
          getEnv().network.cdn
        }/morpheus/translations/${localeToMorpheus(locale)}/${path}`,
      )
      .json<Translations>();
  });
}

// Admin
const useI18nAdmin =
  defineI18nMorpheus<typeof AdminTranslations>("admin_vue.json");

// Micro FE
const useI18nRegistration = defineI18nMorpheus<
  typeof RegistrationTranslations,
  "be-FR" | "be-NL" | "nl-NL"
>("company_registration_form.json");
const useI18nContact =
  defineI18nMorpheus<typeof ContactFormTranslations>("contact_form.json");

// Shared
const useI18nReviews =
  defineI18nMorpheus<typeof ReviewsTranslations>("reviews.json");
const useI18nReviewForm =
  defineI18nMorpheus<typeof ReviewFormTranslations>("review_form.json");
const useI18nCompanyCard =
  defineI18nMorpheus<typeof ClientCompanyCardTranslations>("company_card.json");
const useI18nGeneral =
  defineI18nMorpheus<typeof GeneralTranslations>("general.json");
const useI18nAddressInput =
  defineI18nMorpheus<typeof AddressInputTranslations>("address_input.json");
const useI18nCommonFe =
  defineI18nMorpheus<typeof CommonFeTranslations>("common_fe.json");
const useI18nInbox = defineI18nMorpheus<typeof InboxTranslations>("inbox.json");

// Main Site
const useI18nMainSite =
  defineI18nMorpheus<typeof MainSiteTranslations>("client.json");
const useI18nMainSiteBlog = defineI18nMorpheus<typeof MainSiteBlogTranslations>(
  "main_site_blog.json",
);
const useI18nMainSiteHomepage = defineI18nMorpheus<
  typeof MainSiteHomepageTranslations
>("main_site_homepage.json");
const useI18nMainSiteCompanyWall = defineI18nMorpheus<
  typeof MainSiteCompanyWallTranslations
>("main_site_company_wall.json");
const useI18nMainSiteSearch = defineI18nMorpheus<
  typeof MainSiteSearchTranslations
>("main_site_search.json");
const useI18nMainSiteAffiliate = defineI18nMorpheus<
  typeof MainSiteAffiliateTranslations
>("main_site_affiliate.json");
const useI18nMainSiteBrochure = defineI18nMorpheus<
  typeof MainSiteBrochureTranslations
>("main_site_brochure.json");

// My Solvari
const useI18nMySolvari =
  defineI18nMorpheus<typeof MyTranslations>("my_solvari.json");

// Customer
const useI18nCustomer =
  defineI18nMorpheus<typeof CustomerTranslations>("pro_vue.json");
const useI18nCustomerCrmApi = defineI18nMorpheus<
  typeof CustomerCrmApiTranslations
>("customer_crm_api.json");
const useI18nCustomerProjects = defineI18nMorpheus<
  typeof CustomerProjectsTranslations
>("customer_projects.json");
const useI18nCustomerFinancialDashboard = defineI18nMorpheus<
  typeof CustomerFinancialDashboard
>("customer_financial_dashboard.json");

export {
  useI18nAddressInput,
  useI18nAdmin,
  useI18nCommonFe,
  useI18nCompanyCard,
  useI18nContact,
  useI18nCustomer,
  useI18nCustomerCrmApi,
  useI18nCustomerFinancialDashboard,
  useI18nCustomerProjects,
  useI18nGeneral,
  useI18nInbox,
  useI18nMainSite,
  useI18nMainSiteAffiliate,
  useI18nMainSiteBlog,
  useI18nMainSiteBrochure,
  useI18nMainSiteCompanyWall,
  useI18nMainSiteHomepage,
  useI18nMainSiteSearch,
  useI18nMySolvari,
  useI18nRegistration,
  useI18nReviewForm,
  useI18nReviews,
};
export type { FormsTranslations };
