<template>
  <SAutocomplete v-bind="autocomplete.props" v-on="autocomplete.on">
    <template v-for="(_index, slotName) in slots" #[slotName]="data">
      <slot :name="slotName" v-bind="data" />
    </template>
  </SAutocomplete>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

import type { UseComboboxPresetEmit } from "@/lib/components/logic/molecules/useComboboxPreset";

import * as useComboboxPreset from "@/lib/components/logic/molecules/useComboboxPreset";
import SAutocomplete from "@/lib/components/molecules/autocomplete/SAutocomplete.vue";

const props = defineProps(useComboboxPreset.props);
const emit = defineEmits<UseComboboxPresetEmit>();

const { autocomplete } = useComboboxPreset.use(props, emit);

const slots: Record<string, unknown> = useSlots();
</script>
