<template>
  <div class="form-question">
    <STextInput
      v-model="phone"
      class="s-form__phone"
      :label="tr('forms.fields.phone.label')"
      :maxlength="255"
      name="phone"
      required
      show-required-type="none"
      type="tel"
      v-on="reEmit(emit, ['validationError'])"
      @focus="emit('focus', 'phone')"
    />
    <div v-if="locale === 'nl-NL' && isNlPhone" class="s-form__whatsapp">
      <FontAwesomeIcon class="s-form__whatsapp__icon" :icon="faWhatsapp" />
      <SCheckbox
        v-model="whatsappAgreement"
        :label="tr('forms.fields.whatsappAgreement.label')"
        name="whatsappAgreement"
        v-on="reEmit(emit, ['focus', 'validationError'])"
        @update:model-value="gtmStore.whatsappOptIn()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { reEmit } from "@solvari/common-fe/helpers";
import { computed, toRef } from "vue";

import { SCheckbox } from "@solvari/common-fe";
import { STextInput } from "@solvari/components";

import { useI18nForms } from "@/plugins/i18n.ts";
import { useGtmStore } from "@/plugins/store/gtm";
import { useLeadStore } from "@/plugins/store/lead";

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const leadStore = useLeadStore();
const gtmStore = useGtmStore();
const { tr, locale } = useI18nForms();

const phone = toRef(leadStore, "phone");
const isNlPhone = computed(() => {
  const sanitised = phone.value.replaceAll(/[^1-9]/g, "");
  return sanitised.startsWith("6") || sanitised.startsWith("316");
});
const whatsappAgreement = toRef(leadStore, "whatsappAgreement");
</script>

<style lang="postcss">
.s-form__phone {
  @apply mb-2;
}
.s-form__whatsapp {
  @apply flex items-center gap-2;
  &__icon {
    @apply h-4 w-4 rounded-full bg-accent-400 p-1 text-white;
  }
}
</style>
