import { bestMatch } from "@solvari/common-fe/helpers";
import { defineRule } from "@solvari/common-fe/validation";
import { computed, type MaybeRefOrGetter, toValue } from "vue";

import type { LocaleMorpheus } from "@solvari/translations";

const emailDomainsGeneral = Object.freeze([
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "outlook.com",
  "icloud.com",
  "msn.com",
]);

const emailDomainsNlNL = Object.freeze([
  ...emailDomainsGeneral,
  "ziggo.nl",
  "casema.nl",
  "tele2.nl",
  "kpn.nl",
  "kpnplanet.nl",
  "kpnmail.com",
  "kpnmail.nl",
  "zonnet.nl",
  "zeelandnet.nl",
  "upcmail.nl",
  "planet.nl",
  "outlook.nl",
  "live.nl",
  "icloud.nl",
  "caiway.nl",
  "hetnet.nl",
  "home.nl",
]);

const emailDomainsBE = Object.freeze([
  ...emailDomainsGeneral,
  "telenet.be",
  "skynet.be",
  "pandora.be",
  "live.be",
]);

const emailDomainsFrFR = Object.freeze([
  ...emailDomainsGeneral,
  "hotmail.fr",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "live.fr",
]);

const emailDomains = {
  "nl-NL": emailDomainsNlNL,
  "be-NL": emailDomainsBE,
  "be-FR": emailDomainsBE,
  "fr-FR": emailDomainsFrFR,
} as const satisfies Record<LocaleMorpheus, readonly string[]>;

function useEmailTypoCorrection(
  email: MaybeRefOrGetter<string | null | undefined>,
  locale: MaybeRefOrGetter<LocaleMorpheus>,
) {
  const domains = computed(() => emailDomains[toValue(locale)]);

  const emailParts = computed(() => {
    const emailValue = toValue(email);
    if (typeof emailValue !== "string") {
      return {
        username: "",
        domain: "",
        tld: "",
        secondLevelDomain: "",
      };
    }
    const parts = emailValue.split("@");
    const domain = parts[1]?.toLowerCase() || "";
    const splitDomain = domain.split(".");
    return {
      username: parts[0]?.toLowerCase() || "",
      domain,
      tld: splitDomain[splitDomain.length - 1] || "",
      secondLevelDomain: splitDomain[0] || "",
    };
  });

  const suggestedDomain = computed(() => {
    return bestMatch(emailParts.value.domain, domains.value, {
      threshold: 0.85,
    });
  });

  const suggestion = computed(() => {
    if (
      !suggestedDomain.value ||
      suggestedDomain.value === emailParts.value.domain
    ) {
      return null;
    }
    return `${emailParts.value.username}@${suggestedDomain.value}`;
  });

  const typoSuggestRule = defineRule({
    name: "emailTypoSuggest",
    validate: () => !suggestion.value,
    events: ["blur"],
    color: "warning",
    blocking: false,
    message: "",
    component: "emailTypoSuggest",
  });

  return { typoSuggestRule, suggestion };
}

export { useEmailTypoCorrection };
