<template>
  <BaseInput
    v-bind="props"
    :class="props.class"
    :rules="[...inferredRules, ...(props.rules ?? [])]"
    v-on="
      reEmit(emit, ['update:loading', 'validationError', 'update:modelValue'])
    "
  >
    <template #input="{ id, describedBy, errorId, listeners, invalid }">
      <PInputText
        v-bind="$attrs"
        :id
        v-model="modelValue"
        :aria-describedby="describedBy"
        :aria-errormessage="errorId"
        :disabled
        fluid
        :invalid
        :maxlength
        :minlength
        :name
        :pt="assign({ root: { name } }, pt?.pcInput ?? {})"
        :required
        :size
        v-on="listeners"
        @focus="emit('focus', name)"
      />
    </template>
    <template v-for="(_index, slotName) in $slots" #[slotName]="data">
      <slot :name="slotName" v-bind="data" />
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import type { InferTextInputRulesProps } from "@solvari/common-fe/validation";
import type { InputTextPassThroughOptions } from "primevue";

import { useModel } from "@solvari/common-fe/composables";
import { reEmit } from "@solvari/common-fe/helpers";
import { inferTextInputRules } from "@solvari/common-fe/validation";
import { assign } from "radash";

import type {
  BaseInputEmits,
  BaseInputPassThroughOptions,
  BaseInputProps,
} from "@/molecules/baseInput.ts";

import BaseInput from "@/molecules/BaseInput.vue";
import { PInputText } from "@/primeVueExports.ts";

const props = defineProps<
  BaseInputProps<string | null | undefined> &
    InferTextInputRulesProps & {
      class?: string;
      pt?: BaseInputPassThroughOptions & {
        pcInput?: InputTextPassThroughOptions;
      };
    }
>();
const emit = defineEmits<
  BaseInputEmits &
    ((event: "focus", name: string) => void) &
    ((event: "update:modelValue", value: string | null | undefined) => void)
>();

const modelValue = useModel("modelValue", props, emit);

const inferredRules = inferTextInputRules(props);
</script>
