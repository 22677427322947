<template>
  <SAlert
    v-if="isVisible"
    class="s-form-cross-sell-alert"
    color="primary"
    :prefix-icon="faInfoCircle"
  >
    {{
      tr("forms.application.cross_sell", {
        productName: formStore.product?.name ?? "",
      })
    }}
    <br />
    <PButton
      as="a"
      :href="href"
      icon="fa-regular fa-arrow-up-right-from-square"
      icon-pos="right"
      :label="tr('forms.actions.open_application')"
      size="small"
      target="_blank"
      text
    />
  </SAlert>
</template>

<script setup lang="ts">
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { applyArgusUuidToUrl } from "@solvari/common-fe/argus/thin-client";
import { computed } from "vue";

import { SAlert } from "@solvari/common-fe";
import { PButton } from "@solvari/components";

import { useI18nForms } from "@/plugins/i18n.ts";
import { useApplicationStore } from "@/plugins/store/application";
import { useFormStore } from "@/plugins/store/form";

const formStore = useFormStore();
const applicationStore = useApplicationStore();

const isVisible = computed(() => formStore.isCrossSellForm);

const href = computed(() => {
  if (!applicationStore.submitResult?.waitPageUrl) {
    return;
  }
  return applyArgusUuidToUrl(applicationStore.submitResult.waitPageUrl);
});

const { tr } = useI18nForms();
</script>
