import type { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import type { PropType } from "vue";

import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import {
  onClickOutside,
  toReactive,
  useFocusWithin,
  useToString,
} from "@vueuse/core";
import { nanoid } from "nanoid";
import { omit } from "radash";
import { computed, reactive, ref, toRef, watch } from "vue";

import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import type { UseInputIconAtomEmit } from "@/lib/components/logic/atoms/input/useInputIconAtom";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type {
  OptionItemProp,
  OptionValue,
} from "@/lib/composables/useOptionsStore/useOptionsStore";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import { primitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import * as useInputFieldAtom from "@/lib/components/logic/atoms/input/useInputFieldAtom";
import * as useInputIconAtom from "@/lib/components/logic/atoms/input/useInputIconAtom";
import * as useInputWrapperAtom from "@/lib/components/logic/atoms/input/useInputWrapperAtom";
import * as useDescription from "@/lib/components/logic/atoms/useDescription";
import * as useLabel from "@/lib/components/logic/atoms/useLabel";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import {
  useSelectListboxAlignment,
  useSelectListboxAlignmentProps,
  useSelectPlaceholder,
} from "@/lib/components/logic/molecules/useSelect";
import { inferTextInputRules } from "@/lib/components/logic/molecules/useTextInput";
import {
  useDescribedBy,
  useIsOpen,
  useModel,
  useTemplateRef,
} from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useLabelFallback } from "@/lib/composables/useLabelFallback.ts";
import { useActiveItem } from "@/lib/composables/useOptionsStore/useActiveItem";
import {
  useOptionsStore,
  useOptionsStoreScoped,
} from "@/lib/composables/useOptionsStore/useOptionsStore";
import {
  useOptionsStoreFilter,
  useOptionsStoreFilterScoped,
} from "@/lib/composables/useOptionsStore/useOptionsStoreFilter";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useLabel.scoped,
  ...useTooltip.scoped,
  ...useDescription.scoped,
  ...useInputFieldAtom.scopedTextOnly,
  ...useInputIconAtom.scoped,
  ...useInputWrapperAtom.scoped,
  ...useSubtext.scoped,
  ...useValidationProviderScoped<PrimitiveOrArrayValue>(),
  ...useOptionsStoreScoped,
  ...useOptionsStoreFilterScoped,
  name: { type: String, required: true },
  placeholder: { type: String, required: false },
  valueLabel: { type: String, default: "" },
  multiple: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  modelValue: primitiveOrArrayValue,
  items: {
    type: [Array, Object] as PropType<
      OptionItemProp[] | Record<number | string, string> | string[]
    >,
    default: () => [],
  },
  suffixIcon: {
    type: Object as PropType<IconDefinition | null>,
    default: () => faChevronDown,
  },
  matchThreshold: { type: Number, default: 1 },
  clearFilterOnSelect: { type: Boolean, default: false },
  clearFilterOnClose: { type: Boolean, default: true },
  blurOnSelect: { type: Boolean, default: false },
  openOnInput: { type: Boolean, default: true },
  openOnFocus: { type: Boolean, default: false },
  allowTypedItems: { type: Boolean, default: false },
  firstItemAlwaysActive: { type: Boolean, default: true },
  ...useSelectListboxAlignmentProps,
});

const emits = emitsDefinition([
  "update:modelValue",
  "focus",
  "blur",
  "update:filter",
  "update:loading",
  ...useInputIconAtom.emits,
  ...useValidationProviderEmits,
]);

type UseAutocompleteProps = DefineProps<typeof props>;
type UseAutocompleteEmit = UseInputIconAtomEmit &
  UseValidationProviderEmits & {
    (event: "blur" | "focus" | "update:filter", value: string): void;
    (event: "update:loading", value: boolean): void;
    (event: "update:modelValue", value: PrimitiveOrArrayValue): void;
  };

function use(props: UseAutocompleteProps, emit: UseAutocompleteEmit) {
  const modelValue = useModel("modelValue", props, emit, { local: true });

  const loading = useModel("loading", props, emit, { local: true });
  const filter = useModel("filter", props, emit, { local: true });

  const { isOpen, open, close, toggleOpen } = useIsOpen(
    { onClose },
    { openable: () => !props.disabled },
  );

  // Translations
  const { label, errorLabel } = useLabelFallback(
    toRef(() => props.name),
    reactivePick(props, ["label", "errorLabel"]),
  );

  const placeholder = useSelectPlaceholder(toRef(() => props.placeholder));

  // Ids
  const { describedBy, ids } = useDescribedBy(
    reactive({
      tooltip: toRef(() => props.tooltip),
      description: toRef(() => props.description),
      subtext: toRef(() => props.subtext),
    }),
  );
  const id = nanoid(10);
  const listboxId = ref(nanoid(10));

  // Refs
  const { el: containerEl, ref: containerRef } = useTemplateRef();
  const { el: comboboxContainerEl, ref: comboboxContainerRef } =
    useTemplateRef();
  const { el: comboboxEl, ref: comboboxRef } = useTemplateRef();
  const { el: listboxEl, ref: listboxRef } = useTemplateRef();

  useSelectListboxAlignment(
    comboboxContainerEl,
    listboxEl,
    () => props.alignmentOffset,
    () => props.alignmentPadding,
    () => props.alignmentOverflow,
  );

  const {
    validationListeners,
    error,
    errorComponent,
    errorProps,
    inputProps,
    validating,
  } = useValidationProvider(
    modelValue,
    mergeReactive(props, {
      rules: [...inferTextInputRules(props).value, ...props.rules],
      errorLabel,
    }),
    emit,
  );

  const { focused: isFocused } = useFocusWithin(containerEl);
  watch(isFocused, (isFocused) => {
    if (isFocused) {
      void validationListeners.value.focus?.();
      emit("focus", props.name);
      if (props.openOnFocus) {
        open();
        resetActiveItem();
      }
    } else {
      void validationListeners.value.blur?.();
      emit("blur", props.name);
    }
  });

  const {
    activeItemValue,
    allItems,
    items,
    findItemIndex,
    setChecked,
    setIsOpen,
    tryToggleValue,
    applyClosestMatch,
    addValue,
    removeValue,
  } = useOptionsStore(modelValue, props);

  const multiValueShown = computed(() => {
    return !!allItems.value.length && props.multiple;
  });

  const visibleItems = useOptionsStoreFilter(
    filter,
    items,
    toRef(() => props.disableFilter),
  );

  const {
    activeItem,
    activeDescendantId,
    setActiveItemByValue,
    setActiveItemByIndex,
    setNextActiveItem,
    setPreviousActiveItem,
    setFirstVisibleActive,
  } = useActiveItem(modelValue, activeItemValue, visibleItems, findItemIndex);

  function resetActiveItem() {
    if (props.firstItemAlwaysActive) {
      setFirstVisibleActive();
    } else {
      activeItemValue.value = null;
    }
  }

  watch([toRef(() => props.items), filter], () => {
    if (isOpen.value) {
      resetActiveItem();
    }
  });

  // Filter
  watch(filter, syncModelToFilter, { flush: "sync" });

  function syncModelToFilter() {
    if (
      !props.allowTypedItems ||
      props.multiple ||
      modelValue.value === filter.value ||
      allItems.value[0]?.label === filter.value
    ) {
      return;
    }
    modelValue.value = filter.value;
  }

  watch(modelValue, syncFilterToItem, { immediate: true });

  function syncFilterToItem() {
    if (
      props.multiple ||
      (props.allowTypedItems && modelValue.value === filter.value) ||
      (allItems.value[0]?.label ?? "") === filter.value
    ) {
      return;
    }
    filter.value = allItems.value[0]?.label ?? "";
  }

  function onEnter() {
    if (activeItem.value) {
      tryToggleValue(activeItem.value.value);
      itemSelected();
      return;
    }
    if (!props.allowTypedItems) {
      return;
    }
    const match = applyClosestMatch(filter.value, props.matchThreshold);
    if (!match && props.multiple) {
      addValue(filter.value.trim());
    }
    itemSelected();
  }

  onClickOutside(containerEl, () => {
    if (isOpen.value) {
      close();
      if (!props.multiple) {
        applyClosestMatch(filter.value, props.matchThreshold);
      }
    }
  });

  function onContainerClick(event: PointerEvent) {
    // Prevents focus loss on click on a sibling of the input element
    // Otherwise we get blur on mousedown and focus on mouseup
    if (event.target !== comboboxEl.value) {
      event.preventDefault();
    }
    comboboxEl.value?.focus();
  }

  function onClose() {
    activeItemValue.value = null;

    if (!props.multiple) {
      syncFilterToItem();
    }

    if (props.blurOnSelect) {
      comboboxEl.value?.blur();
    }

    if (props.multiple && props.clearFilterOnClose) {
      filter.value = "";
    }
  }

  function onListboxClick(value: OptionValue) {
    setActiveItemByValue(value);
  }

  function onListboxInput(
    checked: boolean,
    value: OptionValue | OptionValue[],
  ) {
    setChecked(checked, value);
    itemSelected();
  }

  function itemSelected() {
    if (!props.multiple) {
      close();
      return;
    }

    if (props.clearFilterOnSelect) {
      filter.value = "";
    }
  }

  function onComboboxInput(value: string) {
    filter.value = value;
    if (props.openOnInput) {
      open();
      resetActiveItem();
    }
  }

  function onComboboxKeydown(event: KeyboardEvent) {
    if (isOpen.value) {
      onKeydownOpen(event);
    } else {
      onKeydownClosed(event);
    }
  }

  function onKeydownClosed(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp": {
        event.preventDefault();
        open();
        setFirstVisibleActive();
        break;
      }
      case "Enter": {
        event.preventDefault();
        open();
        setFirstVisibleActive();
        break;
      }
      case "Home": {
        event.preventDefault();
        open();
        setActiveItemByIndex(0);
        break;
      }
      case "End": {
        event.preventDefault();
        open();
        setActiveItemByIndex(visibleItems.value.length - 1);
        break;
      }
    }
  }

  function onKeydownOpen(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowDown": {
        event.preventDefault();
        setNextActiveItem();
        break;
      }
      case "ArrowUp": {
        event.preventDefault();
        setPreviousActiveItem();
        break;
      }
      case "ArrowRight": {
        if (activeItem.value?.children?.length && !activeItem.value.isOpen) {
          event.preventDefault();
          setIsOpen(activeItem.value.value, true);
          setNextActiveItem();
        }
        break;
      }
      case "ArrowLeft": {
        if (activeItem.value?.children?.length && activeItem.value.isOpen) {
          event.preventDefault();
          setIsOpen(activeItem.value.value, false);
        }
        break;
      }
      case "Home": {
        event.preventDefault();
        setActiveItemByIndex(0);
        break;
      }
      case "End": {
        event.preventDefault();
        setActiveItemByIndex(visibleItems.value.length - 1);
        break;
      }
      case "PageDown": {
        setNextActiveItem(10);
        break;
      }
      case "PageUp": {
        setPreviousActiveItem(10);
        break;
      }
      case "Enter": {
        event.preventDefault();
        onEnter();
        break;
      }
      case "Tab":
      case "Escape": {
        close();
        if (!props.multiple) {
          applyClosestMatch(filter.value, props.matchThreshold);
        }
        break;
      }
    }
  }

  /*
    Components
   */
  const container = { ref: containerRef, on: { mousedown: onContainerClick } };

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabel.scoped), { for: id, label }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltip.scoped), {
      tooltipId: toRef(() => ids.tooltip),
    }),
  };

  const descriptionAtom = {
    if: computed(() => !!props.description),
    props: mergeReactive(pickProps(props, useDescription.scoped), {
      descriptionId: toRef(() => ids.description),
    }),
  };

  const inputAtom = {
    ref: comboboxContainerRef,
    props: mergeReactive(
      pickProps(props, {
        ...useInputFieldAtom.scopedTextOnly,
        ...useInputIconAtom.scoped,
        ...useInputWrapperAtom.scoped,
      }),
      inputProps,
      {
        inputRef: comboboxRef,
        modelValue: filter,
        loading: toRef(() => loading.value || validating.value),
        placeholder,
        "aria-controls": listboxId,
        "aria-activedescendant": activeDescendantId,
        "aria-autocomplete": "list",
        "aria-expanded": useToString(isOpen),
        role: "combobox",
        id,
        describedBy,
        suffixIconButton: toRef(() => Boolean(props.suffixIcon)),
      },
    ),
    on: mergeListeners(
      {
        keydown: onComboboxKeydown,
        "update:modelValue": onComboboxInput,
        clickSuffixIcon: toggleOpen,
      },
      omit(toReactive(validationListeners), ["focus", "blur"]),
    ),
  };

  const listboxAtom = {
    ref: listboxRef,
    props: reactive({
      listboxId,
      items: visibleItems,
      size: toRef(() => props.size),
      multiple: toRef(() => props.multiple),
    }),
    on: {
      click: onListboxClick,
      "update:modelValue": onListboxInput,
      "update:isOpen": setIsOpen,
    },
  };

  const subtextAtom = {
    if: computed(() => !!props.subtext),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtextId: toRef(() => ids.subtext),
    }),
  };

  const multiValueDisplayAtom = reactive({
    if: multiValueShown,
    props: { items: allItems, variant: "opaque" as const },
    on: { removeValue },
  });

  return {
    container,
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    subtextAtom,
    inputAtom,
    listboxAtom,
    isOpen,
    isFocused,
    multiValueDisplayAtom,
    valueLabel: toRef(() => props.valueLabel),
    error,
    errorComponent,
    errorProps,
  };
}

export { emits, props, use };
export type { UseAutocompleteEmit, UseAutocompleteProps };
